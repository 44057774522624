import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import './privacypolicy.css'
import { useNavigate, useParams } from "react-router-dom";


import { privacydatas } from '../../Api/privacy';

import Header from '../../layouts/Header/header';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';




export default function Privacypolicy() {

    const [privacy_policy, setprivacypolicy] = useState("")

    const history = useNavigate();
    const getprivacypolicy = async () => {

        var privacy = await privacydatas();
        console.log(privacy, "cpppp")

        setprivacypolicy(privacy.privacyyy);


    }
    useEffect(() => {
        getprivacypolicy()
    }, []);





    return (
        <>

            <Header />
            {/* <!-- Privacy Policy Section --> */}
            <div className='allbody'>

                <section class="privacysection">
                    <div class="container">

                        <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>


                        <div class="card" style={{ marginTop: "10px" }}>

                            <div dangerouslySetInnerHTML={{ __html: privacy_policy }} />
                        </div>
                    </div>




                </section >
            </div>


            {/* <!-- Privacy Policy Section Ends --> */}










        </>

    );
}