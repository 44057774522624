import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './myorder.css'

import Sidebars from './sidebar';
import { GiTwoCoins } from "react-icons/gi";

import { orderdatas } from '../../Api/orderhistory';

import { orderagaindatas } from '../../Api/orderagain';

import { cancelorderdatas, cancelordertriggerdatas } from '../../Api/cancelorder';
import Header from '../../layouts/Header/header';
import swal from "sweetalert";
import { fetchhomeproducts } from '../../Slices/homeproducts';
import { useDispatch } from 'react-redux';
import { fetchcart } from '../../Slices/fetchcart';
import { Circles, RotatingLines } from 'react-loader-spinner';
import { getPointsHistory, getaccountdetaildatas } from '../../Api/accountprofile';
import moment from 'moment';



export default function Orderhistorys() {

  const dispatch = useDispatch()

  useEffect(() => {

    getorders()
    setloading(true)
    //  getcart()
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  //    const getcart = () => {
  //   const token = localStorage.getItem("user_token");
  //   dispatch(fetchcart(token))
  // }
  const [loading, setloading] = useState(false)

  const [orderindex, setOrderindex] = useState(0);

  const [orderhistory, setorderhistory] = useState([]);
  const [cancelorderhistory, setcancelorderhistory] = useState([]);

  const [cancelremoveorder, setcancelremoveorder] = useState([]);
  const [totalCoins, setTotalCoins] = useState(0);

  const getorders = async () => {
    const token = localStorage.getItem("user_token");



    var test = await getaccountdetaildatas(token);
    setTotalCoins(test.user_details.reward_points)
    const response = await getPointsHistory(token)
    console.log("response", response.data)
    setorderhistory(response.data)
    setloading(false)

  }



  const cancelorders = async () => {
    var id = orderhistory[orderindex].id
    console.log("idddd", id)
    const token = localStorage.getItem("user_token");
    console.log("test", orderindex)
    const cancelordering = await cancelordertriggerdatas(id, token)

    if (cancelordering.message == "Order cancelled !") {
      window.location.reload()
      swal({
        title: "Order cancelled !",
        icon: "success",
        timer: 2000
      })

    }
    // window.location = "/canceldetail"

  }


  const orderagainlist = async (id) => {
    // alert("hhh")
    const token = localStorage.getItem("user_token");
    const orderagainz = await orderagaindatas(token, id)

    swal({
      title: orderagainz,
      icon: "success",
      timer: 2000
    })
    console.log("orderagainzlist", orderagainz)
    dispatch(fetchcart(token, 'initial'))
    setTimeout(() => {
      window.location = "/cart"
    }, 1000);

  }



  const vieworderdetails = (id) => {

    window.location = "/vieworderdetail/" + id

  }
  const [order, setOrder] = useState(false)

  return (

    <>


      <Header />

      <div className='allbody'>
        <div>


          <Container>

            <Row>

              <Col md={4}>

                <Sidebars />

              </Col>

              <Col md={8}>
                <h2 className='avilable-bln'>Available Balance : <GiTwoCoins className='coin-icon'></GiTwoCoins>  {totalCoins}</h2>

                <div class="tab-pane fade show active myorder" id="v-pills-ordershistory" role="tabpanel" aria-labelledby="v-pills-ordershistory-tab">

                  <div class="tab-content overflow ordertable" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-orders" role="tabpanel" aria-labelledby="pills-orders-tab">



                          {loading ?
                            <div className='loaderss'>
                              {/* <Circles style={{ justifyContent: "center" }} color="#3ba5df" height={100} width={100} /> */}

                              <RotatingLines
                                strokeColor="#3ba5df"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"

                                visible={true}
                              />
                            </div> :
                            <div>

                              {!orderhistory.length ?
                                <div className='firstsearch'>
                                  <img style={{ height: "450px" }} src={require('../../assets/product-not-found.jpg')} class="img-fluid" alt="" />
                                  {/* <h1>Searching...</h1> */}
                                </div> :

                                <>

                                  {orderhistory.map((e, i) => (
                                    <div className='row history-list'>
                                      <div className='col-lg-10'>
                                        <p>{e.order.order_list[0].item.name}</p>
                                        <div className='d-flex'>
                                          <p>Order Id  #{e.order_id}</p>
                                          <p className='mrg-lft'>Credited on {moment(e.created_at).format("MM-DD-YYYY")} </p>
                                        </div>
                                      </div>
                                      <div className='col-lg-2'>
                                        {e.point_debited && e.point_debited != "0.00" &&

                                          <p className='points-value-red'>-{e.point_debited}</p>
                                        }
                                        {e.point_credited && e.point_credited != "0.00" &&
                                          <p className='points-value-green'>+{e.point_credited}</p>
                                        }

                                      </div>
                                    </div>
                                  ))
                                  }
                                </>
                              }
                            </div>
                          }

                        </div>





                        <div class="tab-pane fade" id="pills-canceledOrders" role="tabpanel" aria-labelledby="pills-canceledOrders-tab">
                          {loading ?
                            <div className='loaderss'>
                              {/* <Circles style={{ justifyContent: "center" }} color="#3ba5df" height={100} width={100} /> */}
                              <RotatingLines
                                strokeColor="#3ba5df"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"

                                visible={true}
                              />
                            </div> :
                            <div>
                              {!cancelorderhistory.length ?
                                <div className='firstsearch'>
                                  <img style={{ height: "450px" }} src={require('../../assets/product-not-found.jpg')} class="img-fluid" alt="" />
                                  {/* <h1>Searching...</h1> */}
                                </div> :
                                <table class="table table-bordered">
                                  <thead>

                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Order ID</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Total</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cancelorderhistory.map((e, i) =>
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{e.id}</td>
                                        <td>{e.created_at}</td>
                                        <td>$ {e.grand_total} for {e.order_list_count} item(s)</td>
                                        <td><button class="redbtn">{e.status}</button></td>
                                        <td>
                                          {/* <a href='/vieworderdetail'> */}
                                          <button class="viewbtn" onClick={() => vieworderdetails(e.id)}>View</button>
                                          {/* </a>  */}
                                          {/* <a href='/buyagain'> */}
                                          <button type="button" onClick={() => orderagainlist(e.id)} class="greenbtn" style={{ marginLeft: "10px" }}>Order again</button>
                                          {/* </a> */}
                                        </td>
                                      </tr>
                                    )}

                                  </tbody>
                                </table>
                              }
                            </div>
                          }
                        </div>
                      </div>
                      {/* <button class="continuebtn">Continue Shopping</button> */}
                    </div>
                  </div>
                </div>

              </Col>

            </Row>
          </Container>



        </div>

      </div>



    </>
  );

}