import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap'
import './contactus.css'

import { MdLocationOn } from 'react-icons/md';
import { FiPhoneCall } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { BiRightArrowAlt } from 'react-icons/bi';
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios"


import Header from '../../layouts/Header/header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchhomeproducts, homeproductsSelector } from '../../Slices/homeproducts';
import { fetchcart } from '../../Slices/fetchcart';
import { getaccountdetaildatas } from '../../Api/accountprofile';
import { URL_ENQUIRY } from '../../Constant';



export default function Contactus() {
    const dispatch = useDispatch()
    const { categories } = useSelector(homeproductsSelector)
    console.log("test", categories)

    useEffect(() => {
        const token = localStorage.getItem("user_token");
        var data = []
        dispatch(fetchhomeproducts(data))
        dispatch(fetchcart(token, "initial"))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });




        getdata()


    }, []);

    const initialValues = { username: "", email: "", phonenumber: "", message: "", lastname: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [messageerror, setMessageerror] = useState("")
    const [captcherr, setCaptcherr] = useState("")


    const getdata = async () => {
        const token = localStorage.getItem("user_token");
        if (token) {
            var test = await getaccountdetaildatas(token);
            console.log(test.user_details.fname, "1234")

            var data = { ...formValues }
            data.username = test.user_details.fname
            data.lastname = test.user_details.lname
            data.email = test.user_details.email
            data.phonenumber = test.user_details.mobile



            setFormValues(data)
        }

    }


    const handleChange = (e, type) => {


        console.log("dsfsdf", e.target.value)

        if (type == "username") {
            var data = { ...formValues }
            data.username = e.target.value
            setFormValues(data)
        }
        else if (type == "email") {
            var data = { ...formValues }
            data.email = e.target.value
            setFormValues(data)
        }
        else if (type == "phonenumber") {
            var data = { ...formValues }
            data.phonenumber = e.target.value
            setFormValues(data)
        }
        else if (type == "message") {
            var data = { ...formValues }
            data.message = e.target.value
            setFormValues(data)
        }
        else if (type == "lastname") {
            var data = { ...formValues }
            data.lastname = e.target.value
            setFormValues(data)
        }
    };

    const handleSubmit = async () => {

        const token = localStorage.getItem("user_token");

        if (formValues.message == "") {

            setMessageerror('The message field is required.')
        }
        else if (captchavalue == false) {
            setMessageerror('')
            setCaptcherr('Please verify the captcha')

        }
        else {
            setCaptcherr('')
            var data = {
                "message": formValues.message
            }

            try {
                const response = await axios({
                    method: "post",
                    url: `${URL_ENQUIRY}`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token
                    },
                    data: data
                })

                console.log("ffffffff", response.data.message)
                swal({
                    title: response.data.message,
                    icon: "success",

                }).then(function () {
                    window.location.reload()
                });

            }
            catch (err) {
                console.log("error", err.response.data)
            }
        }
    };


    const validate = (values) => {

        console.log(values, "bbbbbbbbbbbb")

        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        // const regex1 =  new RegExp(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi);

        if (!values.username) {
            errors.username = "Name is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.phonenumber) {
            errors.phonenumber = "Phonenumber is required!";
        }

        if (!values.message) {
            errors.message = "Message is required!";
        }
        if (!values.lastname) {
            errors.lastname = "lastname is required!";
        }
        return errors;
    };


    const key = "6LdJDkAlAAAAADllaPzGTvkTKEL_j44Qa5njBuka";
    const [captchavalue, setcaptchavalue] = useState(false);

    function onChangerecaptcha(value) {
        console.log("Captcha value");
        setcaptchavalue(true);
    }

    const [notetext, setNotetext] = useState('')




    return (
        <>

            <Header />

            <div className='allbody'>

                {/* <!-- Contact Us --> */}
                <section class="contactus">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="card">
                                    <h1>
                                        Store Information
                                    </h1>
                                    <div class="singlecontent">
                                        <h4>
                                            <img src={require('../../assets/contact-icon1.png')} alt=" Address" />Address

                                        </h4>
                                        <address>
                                            1327 Ashton Rd,<br></br>
                                            Bay 3, Hanover,<br></br>
                                            MD 21076
                                        </address>

                                    </div>
                                    <div class="singlecontent">
                                        <h4>
                                            <img src={require('../../assets/contact-icon2.png')} alt="Phone" />Phone

                                        </h4>
                                        <p>
                                            866-868-8365
                                        </p>

                                    </div>
                                    <div class="">
                                        <h4>
                                            <img src={require('../../assets/contact-icon3.png')} alt="Email" />Email

                                        </h4>
                                        <p>
                                            cs@visityourgift.com
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                {/* <div class="card cardcontact">
                    <h1>
                        Contact Us
                    </h1>
                    
                        <form >
                            <div class="form-group">
                              <label for="fname"> Full Name<span class="spnred">*</span></label>
                              <input type="text" class="form-control" id="fname" aria-describedby="" placeholder="Full Name" required />
                              </div>
                              <div class="form-group">
                                <div class="row">
                                    <div class="col">
                                        <label for="phnum">Phone Number<span class="spnred">*</span></label>
                                      <input type="text" id="phnum" class="form-control" placeholder=" Phone Number" required />
                                    </div>
                                    <div class="col">
                                        <label for="email">Email Address<span class="spnred">*</span></label>
                                      <input type="email" id="email" class="form-control" placeholder="Email Address" required  />
                                    </div>
                                  </div>
                                </div>
                            <div class="form-group">
                              <label for="desp">Description</label>
                              <textarea type="description" class="form-control" id="desp" placeholder="Description"  >

                              </textarea>
                            </div>
                            
                            <button type="submit" class="btn btn-primary">Submit</button>
                          </form>
                    
                </div> */}


                                {/* <div class="martop10">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.595379121299!2d-76.70761048485674!3d39.161183679530815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7e3b9503fffff%3A0xc86a9f60b76a2802!2s1327%20Ashton%20Rd%20Ste%203%2C%20Hanover%2C%20MD%2021076%2C%20USA!5e0!3m2!1sen!2sin!4v1670238251064!5m2!1sen!2sin" width="100%" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />

                                </div> */}


                                <div className='cotback'>
                                    <Container>
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    <h1 className='readytext'>
                                                        Please fill the below form to reach us.
                                                    </h1>

                                                </div>
                                                <div>

                                                    <Form>

                                                        <Row className='mtop10'>

                                                            <Col md={6}>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                    <Form.Control type="text" placeholder="first name *" value={formValues.username}
                                                                        onChange={(e) => handleChange(e, "username")} />
                                                                    <p className='errormsg'>{formErrors.username}</p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                    <Form.Control type="text" placeholder=" last name *" value={formValues.lastname}
                                                                        onChange={(e) => handleChange(e, "lastname")} />
                                                                    <p className='errormsg'>{formErrors.lastname}</p>
                                                                </Form.Group>
                                                            </Col>





                                                            <Col md={6}>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                    <Form.Control type="email" placeholder="email address *" value={formValues.email}
                                                                        onChange={(e) => handleChange(e, "email")} />
                                                                    <p className='errormsg'>{formErrors.email}</p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                    <Form.Control type="text" placeholder="Phonenumber *" value={formValues.phonenumber}
                                                                        onChange={(e) => handleChange(e, "phonenumber")} />
                                                                    <p className='errormsg'>{formErrors.phonenumber}</p>
                                                                </Form.Group>
                                                            </Col>


                                                            <Col md={6}>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                    <Form.Control as="textarea" rows={3} placeholder="Your message *" value={formValues.message}
                                                                        onChange={(e) => handleChange(e, "message")} />
                                                                    <p className='errormsg'>{messageerror}</p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>

                                                                <div style={{ marginTop: 10 }}>
                                                                    <ReCAPTCHA
                                                                        sitekey="6LcUso0nAAAAAGGz7kf7WfOJcCmwN1RU-SfmzLqW"
                                                                        onChange={onChangerecaptcha}
                                                                    />
                                                                    <p className='errormsg'>{captcherr}</p>

                                                                </div>
                                                            </Col>

                                                        </Row>


                                                        <div >
                                                            <button type="button" onClick={handleSubmit} class="btn btn-primary "><span>Send Message</span> <BiRightArrowAlt className='marbtm4' /></button>
                                                        </div>

                                                    </Form>

                                                </div>


                                            </Col>




                                        </Row>
                                    </Container>
                                </div>




                            </div>

                        </div>
                        <div style={{ marginTop: 10 }}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.595379121299!2d-76.70761048485674!3d39.161183679530815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7e3b9503fffff%3A0xc86a9f60b76a2802!2s1327%20Ashton%20Rd%20Ste%203%2C%20Hanover%2C%20MD%2021076%2C%20USA!5e0!3m2!1sen!2sin!4v1670238251064!5m2!1sen!2sin" width="100%" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                        </div>

                    </div>
                </section>

            </div>
            {/* <!-- Contact Us ends --> */}



        </>

    );
}