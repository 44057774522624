import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './termsandconditions.css'

import { termsdatas } from '../../Api/terms';

import { useNavigate, useParams } from "react-router-dom";

import Header from '../../layouts/Header/header';

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

export default function Termsandconditions() {

    useEffect(() => {
        getterms()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const history = useNavigate();
    const [termss, setterms] = useState("")

    const getterms = async () => {

        var term = await termsdatas();
        console.log(term, "ccdd")

        setterms(term.termss);


    }




    return (
        <>


            {/* 
<!-- Terms & Condition Section --> */}




            <Header />


            <div className='allbody'>

                <section class="privacysection">
                    <div class="container">

                        <Button onClick={() => history(-1)} variant="primary"><BsFillArrowLeftCircleFill style={{ fontSize: "18px", marginTop: "-3px" }} /> Back</Button>


                        <div class="card" style={{ marginTop: "10px" }}>

                        <div dangerouslySetInnerHTML={{ __html: termss }} />

                        </div>



                    </div>

                </section>
            </div>
            {/* <!-- Terms & Condition Section Ends --> */}









        </>

    );
}