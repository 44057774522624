import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router";
import { SUBMITORDER } from "../../Constant";
import axios from "axios";
import swal from "sweetalert";
import './paypal.css'
export default function Paypal() {


    const location = useLocation();
    const storeprice = location.state;

    // Example usage of orderData
    console.log(storeprice, "orderData");

    // useEffect(() => {



    //     getByDisplayValue()



    // }, [])

    // const getByDisplayValue = () => {

    //     var amount = 10.00

    return (
        <div className="payal-design">

            <div style={{ width:"50%"}}>
                <PayPalScriptProvider options={{ "client-id": "AQBONCsk5VhOBtZgebchF2fh02BYWOgAUh5LaueuHvllnxK5pfvW1vsw2naDHHJ6SSuJmp9XyM350SQe" }}>
                    <PayPalButtons
                        // forceReRender={[storeprice, notetext]}
                        createOrder={(data, actions) => {



                            console.log("storeprice", storeprice)
                            var convert
                            if (storeprice.coupenamt > 0) {
                                convert = Number(storeprice.grandtotal - storeprice.coupenamt).toFixed(2)
                            }
                            else {

                                convert = Number(storeprice.grandtotal).toFixed(2)
                            }
                            // const grandtotal = localStorage.getItem("total_amount")

                            console.log("storeprice---->", convert)

                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            value: convert
                                        },
                                    },
                                ],
                            });
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                                if (details.status == "COMPLETED") {


                                    const token = localStorage.getItem("user_token");


                                    var reqdata = {
                                        "d_type": storeprice.deliverytype,
                                        "payment_type": "Paypal",
                                        "transferid": details.purchase_units[0].payments.captures[0].id,
                                        "payid": details.id,
                                        "coupon": storeprice.coupentext,
                                        "note": storeprice.notetext,
                                        "reward_amount_credit": storeprice.useCoinsonly
                                    }



                                    console.log("reqdata", reqdata)

                                    axios({
                                        url: `${SUBMITORDER}`,
                                        method: "post",
                                        data: reqdata,
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: "Bearer " + token

                                        },

                                    })
                                        .then(function (response) {
                                            console.log(response.data, "postalldetailspostalldetailspostalldetails")
                                            if (response.data.message == "Order created successfully") {
                                                swal({
                                                    title: "Order booked Successfully",
                                                    icon: "success",
                                                    timer: 4000
                                                })
                                                setTimeout(() => {
                                                    window.location = '/orderhistory'
                                                }, 1000)

                                            }
                                        })

                                }

                            })
                                .catch((err) => {
                                    console.log("Error on paypal side", err)
                                })


                        }}
                    />
                </PayPalScriptProvider>
            </div>
        </div>
    );

    // }




    return (
        <div></div>
        // <PayPalScriptProvider options={{ "client-id": "AVlwIceIbGLe1tr1kzHn_uQO10pO46E1Es9pJeps5EQrrXYpZklHg1PHZ5z1F995MkP-bZmT5GS13gCH" }}>
        //     <PayPalButtons
        //         createOrder={(data, actions) => {
        //             return actions.order.create({
        //                 purchase_units: [
        //                     {
        //                         amount: {
        //                             value: "1.99",
        //                         },
        //                     },
        //                 ],
        //             });
        //         }}
        //         onApprove={(data, actions) => {
        //             return actions.order.capture().then((details) => {
        //                 const name = details.payer.name.given_name;
        //                 alert(`Transaction completed by ${name}`);
        //             });
        //         }}
        //     />
        // </PayPalScriptProvider>
    );
}

// ReactDOM.render(<PayPalButtonWrapper />, document.getElementById("root"));