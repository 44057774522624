import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import './checkout.css'
import { GiTwoCoins } from "react-icons/gi";

import Header from '../../layouts/Header/header';

import { useNavigate, useParams, Link } from "react-router-dom";
import { cartSelector, fetchcart, gettip_amt } from '../../Slices/fetchcart';
import { useDispatch, useSelector } from 'react-redux';
import { async } from 'q';
import { deliverycharge, submitorder } from '../../Api/submitorder';
import moment from 'moment';
import swal from "sweetalert";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { COUPEN, SUBMITORDER } from '../../Constant';
import axios from 'axios';
import { getaccountdetaildatas } from '../../Api/accountprofile';

export default function Checkout() {

    const history = useNavigate();
    const [prodcuctdisc, setprodcuctdisc] = useState(0)
    const [coupentext, setCoupentext] = useState('')
    const [custom, setCustom] = useState(false)
    const [customtext, setCustomtext] = useState(0)
    const [errcustom, setErrcustom] = useState(false)
    const [payment_type, setpayment_type] = useState('online')
    const navigate = useNavigate();

    const [coupenerr, setCoupenerr] = useState({
        checkcoupen: false,
        validcoupen: false
    })
    const [actual_shipping_charge, setActual_shipping_charge] = useState('')

    const { cartproducts, total, tip_amt } = useSelector(cartSelector)
    const dispatch = useDispatch()
    const { id } = useParams()


    const [tiptype, setTiptype] = useState({
        five: true,
        ten: false,
        fifteen: false,
        twenty: false
    })
    const [deliverytype, setDeliverytype] = useState('standard')
    const [notetext, setNotetext] = useState('')


    const [totacal, setTotacal] = useState({

        taxs: 0,
        grandtotals: 0,
        shipping: {
            standard: 0,
            express: 0
        },
        tip: 0,
        coupenamt: 0

    })

    const [price, setPrice] = useState({
        shipping: {
            standard: 0,
            express: 0
        },
        tip: 0,
        grandtotal: total,
        coupenamt: 0,
        tax: 0
    })








    const [useCoins, setUseCoins] = useState(false);
    const [poinst_amount, setpoint_amount] = useState('');

    const [totalCoins, setTotalCoins] = useState(0);

    const [useCoinsonly, setuseCoinsonly] = useState(0);


    useEffect(() => {
        const token = localStorage.getItem("user_token");
        dispatch(fetchcart(token, 'initial'))

        getallquantity()
        getProfile()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);



    const getProfile = async () => {
        const token = localStorage.getItem("user_token");
        var test = await getaccountdetaildatas(token);

        console.log("testtest", test.user_details.reward_points)
        setTotalCoins(test.user_details.reward_points)
    }

    const [storeprice, setStoreprice] = useState({
        sub_total: 0,
        grandtotal: 0,
        pack_charge: 0,
        coupenamt: 0,
        shipping_chagre: 0
    })
    const [delivery_options, setDelivery_options] = useState({})

    const [delivery_settings, setDelivery_settings] = useState({})

    const [reward_points, setreward_points] = useState('')


    const getallquantity = async () => {

        const token = localStorage.getItem("user_token");

        const response = await deliverycharge(token)
        console.log("response.delivery_settings", response)
        if (Number(response.rewardpoints.min_order) <= Number(response.sub_total)) {

            let pointsPerRupee = Number(response.rewardpoints.reward_points) / Number(response.rewardpoints.min_order);

            // Calculate the points for the target rupees
            let pointsFor124Rupees = pointsPerRupee * (Number(response.sub_total));

            console.log(`Points for 124 rupees: ${Math.ceil(pointsFor124Rupees)}`);
            setreward_points(Math.ceil(pointsFor124Rupees))


        }
        setDelivery_options(response)



        const delivery_settings = response.delivery_settings
        setDelivery_settings(delivery_settings)




        const all_qty = response.products.map(e => {
            return Number(e.quantity)
        })
        //  console.log("all_qty", all_qty)

        const sum_all_qty = all_qty.reduce((a, b) => a + b, 0)

        console.log("sum_all_qty", sum_all_qty)
        console.log("delivery_settings", delivery_settings.shipping_charge_per_quantity)

        const sub_total = sum_all_qty * Number(delivery_settings.shipping_charge_per_quantity)
        console.log("sub_total", sub_total)

        const totalss = sub_total + Number(delivery_settings.minimum_shipping_charge)
        console.log("total", totalss)

        var data = { ...storeprice }
        data.sub_total = totalss
        data.shipping_chagre = totalss
        data.grandtotal = totalss + Number(response.sub_total) + Number(delivery_settings.packing_price)
        data.pack_charge = Number(delivery_settings.packing_price)
        setStoreprice(data)

    }




    const ChoosePaymentMethod = (type) => {

        console.log("type", type)
        setpayment_type(type)

    }




    const changedelivery = (e) => {

        if (e.target.value == "standard") {
            setDeliverytype(e.target.value)
            setTest(true)

            var data = { ...storeprice }
            data.grandtotal = data.sub_total + Number(total) + data.pack_charge
            data.shipping_chagre = data.sub_total
            console.log("data.grandtotal", data.grandtotal)
            setStoreprice(data)



            // var data = { ...price }
            // data.grandtotal = Number(total) + Number(data.shipping.standard) + Number(data.tip)
            // setPrice(data)



        }
        else {
            setDeliverytype(e.target.value)
            setTest(false)

            var data = { ...storeprice }
            data.grandtotal = data.sub_total + Number(delivery_settings.express_delivery_charge) + Number(total) + data.pack_charge
            data.shipping_chagre = data.sub_total + Number(delivery_settings.express_delivery_charge)
            console.log("data.grandtotal", data.grandtotal)
            setStoreprice(data)




            //     var data = { ...price }
            //     data.grandtotal = Number(total) + Number(data.shipping.express) + Number(data.tip)
            //     setPrice(data)

        }

    }


    if (totacal.coupenamt > 0) {
        console.log("cccccccccccc", Number(totacal.grandtotals))
        localStorage.setItem("total_amount", (Number(totacal.grandtotals)))
    }
    else {
        localStorage.setItem("total_amount", (Number(total) + Number(totacal.grandtotals)))
        console.log("allllllllllll", (Number(total) + Number(totacal.grandtotals)))


    }

    const [couponstatus, setcouponstatus] = useState(false)
    const [test, setTest] = useState(false)

    const [couponErr, setcouponErr] = useState("")


    const coupenvalidate = async () => {

        const token = localStorage.getItem("user_token");

        if (!coupentext) {
            // var error = { ...coupenerr }
            // error.checkcoupen = true
            // setCoupenerr(error)
            setcouponErr("Please enter coupon code")

        }
        else {
            try {
                const response = await axios({
                    url: `${COUPEN}` + coupentext,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token

                    },
                    method: "get"

                })
                console.log("resssss", response.data)
                if (response.data.coupon_discount) {
                    setcouponstatus(true)
                    // console.log("coupon Amount", response.data.coupon_discount)
                    var data1 = { ...storeprice }


                    var discount = (Number(total) * Number(response.data.coupon_discount)) / 100

                    data1.coupenamt = Number(discount)

                    console.log("output.coupon_discount", Number(discount))

                    console.log("output.coupon_discount", data1.grandtotal)

                    setStoreprice(data1)

                    // localStorage.setItem("total_amount", ((totacal.grandtotals)))

                    // var error = { ...coupenerr }
                    // error.validcoupen = false
                    // error.checkcoupen = false
                    // setCoupenerr(error)

                }


            }
            catch (err) {
                // var error = { ...coupenerr }
                // error.validcoupen = true
                // error.checkcoupen = false
                // setCoupenerr(error)

                setcouponErr(err.response.data.toast_message)
                console.log("ERROR ===>", err.response.data.toast_message)

            }
        }
    }


    const placeorder = async () => {
        console.log("storeprice", storeprice)
        if (payment_type == "cash") {

            const token = localStorage.getItem("user_token");
            var data = {
                "location_mode": "Delivery",
                "d_type": deliverytype,
                "coupon": couponstatus ? coupentext : "",
                "note": notetext,
                "reward_amount_credit": useCoinsonly
            }
            console.log("coupentext", data)

            const Response = await submitorder(data, token)
            console.log("reqdata", Response)


            if (Response.message == "Order created successfully") {
                swal({
                    title: "Order booked Successfully",
                    icon: "success",
                    timer: 4000
                })
                window.location = '/orderhistory'
            }
        }

        else {

            navigate(`/paypal`, { state: { ...storeprice, deliverytype, notetext, coupentext, useCoinsonly, grandtotal: Number(storeprice.grandtotal) - Number(poinst_amount) } });

            window.location = '/paypal'


        }



    }


    const CouponClear = async () => {

        setCoupentext("")
        var data = { ...totacal }
        data.coupenamt = 0
        data.grandtotals = 0 + Number(data.tip) + Number(data.taxs) + (deliverytype == "standard" ? Number(data.shipping.standard) : deliverytype == "sameday" ? Number(data.shipping.express) : 0)

        console.log("dissss", data.grandtotals)


        setTotacal(data)



    }

    const [check, setCheck] = useState(false)
    // Example: Initial total coins

    const handleCheckboxChange = () => {
        setUseCoins(!useCoins); // Toggle checkbox state

        if (useCoins == false) {

            let rupeesPerPoint = Number(delivery_options.rewardpoints.usd) / Number(delivery_options.rewardpoints.reward_points);

            // Calculate the rupees for the target points
            let rupeesFor25Points = rupeesPerPoint * Number(totalCoins);

            console.log(`Rupees for 25 points: ${rupeesFor25Points}`);
            setpoint_amount(rupeesFor25Points.toFixed(2))
            setuseCoinsonly(totalCoins)

            if (Number(delivery_options.rewardpoints.min_order) <= (Number(delivery_options.sub_total) - Number(rupeesFor25Points))) {

                let pointsPerRupee = Number(delivery_options.rewardpoints.reward_points) / Number(delivery_options.rewardpoints.min_order);

                // Calculate the points for the target rupees
                console.log(`tot ${Number(delivery_options.sub_total) - Number(rupeesFor25Points)}`);


                let pointsFor124Rupees = pointsPerRupee * (Number(delivery_options.sub_total) - Number(rupeesFor25Points))

                console.log(`seccc ${pointsFor124Rupees}`);
                setreward_points(Math.round(pointsFor124Rupees))

            }

        }
        else {

            setpoint_amount(0)
            setuseCoinsonly(0)
            // setreward_points(0)
        }











    };

    const handleConfirm = () => {
        if (useCoins) {
            // Example: Deduct coins from total
            setTotalCoins(totalCoins - 50); // Deducting 50 coins
            // Additional logic for applying coins as per your use case
        }
        // Other confirmation logic
    };

    return (
        <>

            <Header dataparenttochild={"cartminus"} />

            <div className='allbody'>
                {/* <!-- Check Out Page --> */}
                <section class="finalcheckout ">

                    <div class="container">
                        <div class="row" >



                            <div class="col-lg-6">
                                {id == "Delivery" ?
                                    <>
                                        <div className='selectdelivery'>
                                            <h4>Select Your Delivery</h4>
                                        </div>
                                        <div class="card">

                                            <div class="chcklabel">
                                                <input type="radio" onChange={(value) => changedelivery(value)} name="delivery" value="standard" checked={deliverytype == "standard" ? true : false} />
                                                <label class="ml10" style={{ fontWeight: "700" }}>Standard  Delivery  (Within 48 hours)</label>

                                            </div>
                                            <div class="chcklabel">
                                                <input type="radio" onChange={(value) => changedelivery(value)} name="delivery" value="sameday" checked={deliverytype == "sameday" ? true : false} />
                                                <label class="ml10" style={{ fontWeight: "700" }}>Express Delivery  (Within 24 hours)</label>

                                            </div>

                                        </div>
                                    </> : null}






                                <div className="flipkart-coins-container card">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={useCoins}
                                            onChange={handleCheckboxChange}
                                        />
                                        Use Coins (You have  <GiTwoCoins className='coin-icon'></GiTwoCoins> {totalCoins} coins)
                                    </label>
                                    {useCoins ?
                                        <>
                                            <input
                                                value={useCoinsonly}
                                                type="text"
                                                class="form-control marlef marbotm grey-placeholder"
                                                id="formGroupExampleInput"
                                                placeholder="Code Number"
                                                onChange={(e) => {

                                                    if (Number(e.target.value) <= Number(totalCoins)) {
                                                        setuseCoinsonly(e.target.value)
                                                        let rupeesPerPoint = Number(delivery_options.rewardpoints.usd) / Number(delivery_options.rewardpoints.reward_points);

                                                        // Calculate the rupees for the target points
                                                        let rupeesFor25Points = rupeesPerPoint * Number(e.target.value);

                                                        console.log(`Rupees for 25 points: ${rupeesFor25Points}`);
                                                        setpoint_amount(rupeesFor25Points.toFixed(2))


                                                        if (Number(delivery_options.rewardpoints.min_order) <= Number(delivery_options.sub_total) - Number(rupeesFor25Points)) {

                                                            let pointsPerRupee = Number(delivery_options.rewardpoints.reward_points) / Number(delivery_options.rewardpoints.min_order);

                                                            // Calculate the points for the target rupees
                                                            console.log(`tot ${Number(delivery_options.sub_total) - Number(rupeesFor25Points)}`);


                                                            let pointsFor124Rupees = pointsPerRupee * (Number(delivery_options.sub_total) - Number(rupeesFor25Points))

                                                            console.log(`Points for 124 rupees: ${pointsFor124Rupees}`);
                                                            setreward_points(Math.round(pointsFor124Rupees))

                                                        }


                                                    }



                                                }}
                                            />

                                            <label>
                                                Reduction Amount : {poinst_amount}
                                            </label>
                                        </>
                                        : null}
                                    <br />

                                </div>







                                <div class="mt10">
                                    <h4>Cart Total</h4>
                                </div>

                                {/* <div>
                                    <div className='savings' style={{ marginBottom: "10px" }}>
                                        <p className='zoom-in-zoom-out mabo'>Your total savings $ {prodcuctdisc}</p>
                                    </div>
                                </div> */}


                                <div class="card">

                                    <div class="chcklabelnext">






                                        {cartproducts.map((e, i) =>
                                            <Row>
                                                {/* <Col md={9}> */}
                                                <div className='col-md-9 col-8'>
                                                    <>
                                                        <p class="product" style={{ marginBottom: "0px" }}>
                                                            <span>{e.items.name}</span> <span>({e.item_variant.variant_value})</span> <span>({e.quantity} x {e.item_variant.offer_price ? (e.item_variant.offer_price) : e.item_variant.price})</span>
                                                        </p>

                                                        {/* <p style={{ marginBottom:"0px" }}>({e.item_variant.variant_value})</p> */}
                                                    </>
                                                </div>

                                                {/* </Col> */}
                                                {/* <Col md={3}> */}
                                                <div className='col-md-3 col-4'>
                                                    <p class="productvalue" style={{ marginBottom: "0px" }}>
                                                        ${e.total_amount}
                                                    </p>
                                                </div>
                                                {/* </Col> */}
                                            </Row>
                                        )}


                                        <hr></hr>

                                        <div class="row">
                                            <div class="col">

                                                <h6 class="product">
                                                    Sub Total
                                                </h6>
                                            </div>
                                            <div class="col">
                                                {!useCoins ?
                                                    <p class="productvalue" >
                                                        ${parseFloat(total).toFixed(2)}
                                                    </p>
                                                    :
                                                    <p class="productvalue" >
                                                        ${parseFloat(total).toFixed(2)}   + <GiTwoCoins className='coin-icon'></GiTwoCoins> {useCoinsonly}
                                                    </p>
                                                }
                                            </div>

                                            {storeprice.coupenamt > 0 ?
                                                <div class="row">
                                                    <div class="col">

                                                        <h6 class="product">
                                                            Coupon amount
                                                        </h6>
                                                    </div>
                                                    <div class="col">

                                                        <p class="productvalue" style={{ marginRight: "-20px" }}>
                                                            -  ${parseFloat(storeprice.coupenamt).toFixed(2)}
                                                        </p>

                                                    </div>

                                                </div> : null}

                                            {storeprice.coupenamt > 0 ?
                                                <div class="row">
                                                    <div class="col">

                                                        <h6 class="product">
                                                            Total
                                                        </h6>
                                                    </div>
                                                    <div class="col">

                                                        <p class="productvalue" style={{ marginRight: "-20px" }}>
                                                            ${parseFloat(total - (storeprice.coupenamt)).toFixed(2)}
                                                        </p>

                                                    </div>

                                                </div> : null}

                                        </div>
                                        <hr></hr>







                                        <div class="row">
                                            <div class="col">

                                                <h6 class="product">
                                                    Shipping Charge
                                                </h6>
                                            </div>
                                            <div class="col">

                                                <p class="productvalue">
                                                    ${(storeprice.shipping_chagre).toFixed(2)}
                                                </p>

                                            </div>
                                            <hr></hr>

                                        </div>






                                        <div class="row">
                                            <div class="col">

                                                <h6 class="product">
                                                    Packing Charge
                                                </h6>
                                            </div>
                                            <div class="col">

                                                <p class="productvalue">
                                                    ${(storeprice.pack_charge).toFixed(2)}
                                                </p>

                                            </div>
                                            <hr></hr>

                                        </div>



                                        <div class="row" style={{ marginTop: "10px" }}>
                                            <div class="col">
                                                <h6 style={{ fontSize: "17px" }}>
                                                    Grand Total
                                                </h6>

                                            </div>
                                            {!useCoins ?
                                                <div class="col">

                                                    {storeprice.coupenamt > 0 ?

                                                        <h6 class="rghtalgn" style={{ fontSize: "17px" }}>
                                                            ${((storeprice.grandtotal - storeprice.coupenamt)).toFixed(2)}

                                                        </h6>
                                                        :
                                                        <h6 class="rghtalgn" style={{ fontSize: "17px" }}>
                                                            ${((storeprice.grandtotal)).toFixed(2)}


                                                        </h6>

                                                    }





                                                </div>
                                                :
                                                <div class="col">

                                                    {storeprice.coupenamt > 0 ?

                                                        <h6 class="rghtalgn" style={{ fontSize: "17px" }}>
                                                            ${((storeprice.grandtotal - storeprice.coupenamt)).toFixed(2)}  + <GiTwoCoins className='coin-icon'></GiTwoCoins> {useCoinsonly}

                                                        </h6>
                                                        :
                                                        <h6 class="rghtalgn" style={{ fontSize: "17px" }}>
                                                            ${((storeprice.grandtotal)).toFixed(2)}  + <GiTwoCoins className='coin-icon'></GiTwoCoins> {useCoinsonly}


                                                        </h6>

                                                    }





                                                </div>



                                            }



                                        </div>
                                        {useCoins ?
                                            <div class="row">
                                                <div class="col">

                                                    {storeprice.coupenamt > 0 ?

                                                        <h6 class="rghtalgn" style={{ fontSize: "17px" }}>
                                                            ${(Number(storeprice.grandtotal - storeprice.coupenamt) - Number(poinst_amount)).toFixed(2)}

                                                        </h6>
                                                        :
                                                        <h6 class="rghtalgn" style={{ fontSize: "17px" }}>
                                                            ${(Number(storeprice.grandtotal) - Number(poinst_amount)).toFixed(2)}


                                                        </h6>

                                                    }





                                                </div>




                                            </div>
                                            : null}
                                        <label class="get-points">
                                            You will get <GiTwoCoins className='coin-icon'></GiTwoCoins> {reward_points} points from this order
                                        </label>

                                    </div>

                                </div>


                                <div class="mt10">
                                    <h4>Payment Method </h4>
                                </div>

                                {((id == "Delivery") && (!deliverytype)) ?


                                    <div className='card'>
                                        <div class="finalpadding">

                                            <div>
                                                <div><Button className='paywarning' onClick={() => {
                                                    swal({
                                                        title: "Please select delivery type",
                                                        icon: "warning",
                                                        timer: 2000
                                                    })
                                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                                }} variant="warning"> <img src={require('../../assets/download.png')} class="img-fluid" alt="" /></Button>{' '}</div>
                                                <div><Button onClick={() => {
                                                    swal({
                                                        title: "Please select delivery type",
                                                        icon: "warning",
                                                        timer: 2000
                                                    })
                                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                                }} className='paydebit' variant="warning"><img src={require('../../assets/debitsvg.png')} class="img-fluid" alt="" /> Debit or Credit Card</Button>{' '}</div>
                                            </div>

                                            {/* <div>
                                                <div className='paylabel' style={{ marginTop: "3%" }}>
                                                    <input type="checkbox" class="largerCheckbox" id="vehicle1" name="vehicle1" onClick={(e) => checkvalidate(e)} />
                                                    <label for="vehicle1" style={{ marginLeft: "10px" }}> {id == "Delivery" ? "Pay at the time of Delivery" : "Pay at the time of Pickup"}</label>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div> :



                                    <div class="card">
                                        {/* <div class="finalpadding"> */}
                                        <div class="chcklabel">
                                            <input type="radio" onChange={(value) => ChoosePaymentMethod('online')} value={payment_type} checked={payment_type == "online" ? true : false} />
                                            <label class="ml10" style={{ fontWeight: "700" }}>Online Payment</label>

                                        </div>

                                        <div class="chcklabel">
                                            <input type="radio" onChange={(value) => ChoosePaymentMethod('cash')} value={payment_type} checked={payment_type == "cash" ? true : false} />
                                            <label class="ml10" style={{ fontWeight: "700" }}>Cash On Delivery</label>

                                        </div>



                                        {/* <div>
                 <button class="paypalbtn">
                    <img  src={require('../../assets/PayPal.png')} alt="" /><span>Checkout</span>
                    </button>  
                    </div> */}


                                        {/* <div>
                                                 <PayPalScriptProvider options={{ "client-id": "AQBONCsk5VhOBtZgebchF2fh02BYWOgAUh5LaueuHvllnxK5pfvW1vsw2naDHHJ6SSuJmp9XyM350SQe" }}>
                                                    <PayPalButtons
                                                        // forceReRender={[storeprice, notetext]}
                                                        createOrder={(data, actions) => {



                                                            console.log("coupentext", coupentext)
                                                            var convert
                                                            if (storeprice.coupenamt > 0) {
                                                                convert = Number(storeprice.grandtotal - storeprice.coupenamt).toFixed(2)
                                                            }
                                                            else {

                                                                convert = Number(storeprice.grandtotal).toFixed(2)
                                                            }
                                                            // const grandtotal = localStorage.getItem("total_amount")

                                                            console.log("storeprice---->", convert)

                                                            return actions.order.create({
                                                                purchase_units: [
                                                                    {
                                                                        amount: {
                                                                            value: convert
                                                                        },
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        onApprove={(data, actions) => {
                                                            return actions.order.capture().then((details) => {
                                                                if (details.status == "COMPLETED") {


                                                                    const token = localStorage.getItem("user_token");


                                                                    var reqdata = {
                                                                        "d_type": deliverytype,
                                                                        "payment_type": "Paypal",
                                                                        "transferid": details.purchase_units[0].payments.captures[0].id,
                                                                        "payid": details.id,
                                                                        "coupon": coupentext,
                                                                        "note": notetext
                                                                    }



                                                                    console.log("reqdata", reqdata)

                                                                    axios({
                                                                        url: `${SUBMITORDER}`,
                                                                        method: "post",
                                                                        data: reqdata,
                                                                        headers: {
                                                                            'Content-Type': 'application/json',
                                                                            Authorization: "Bearer " + token

                                                                        },

                                                                    })
                                                                        .then(function (response) {
                                                                            console.log(response.data, "postalldetailspostalldetailspostalldetails")
                                                                            if (response.data.message == "Order created successfully") {
                                                                                swal({
                                                                                    title: "Order booked Successfully",
                                                                                    icon: "success",
                                                                                    timer: 4000
                                                                                })
                                                                                setTimeout(() => {
                                                                                    window.location = '/orderhistory'
                                                                                }, 1000)

                                                                            }
                                                                        })

                                                                }

                                                            })
                                                                .catch((err) => {
                                                                    console.log("Error on paypal side", err)
                                                                })


                                                        }}
                                                    />
                                                </PayPalScriptProvider> 
                                            </div> */}


                                        {/* 
                    <div>
                 <button class="Debitcardbtn" onclick="openForm()">
                   <img src={require('../../assets/debitcard.png')}  alt="" />
                   
                </button> 
                </div> */}


                                        {/* <div class="ttttt">
                 <span class="paypalspan"> 
                    Powered by <span class="payclr">Pay</span><span class="palclr">Pal</span>
                 </span>
                </div> */}


                                        {/* <button class="payatdelbtn" onclick="openForm()">
                    <img src={require('../../assets/home.png')} style={{ width:"22px" }}  alt="" /> <span class="ml5px">Pay at the time of Delivery</span>
                    
                 </button>  */}
                                        {/* <div className='paylabel'>
                                                    <input type="checkbox" class="largerCheckbox" id="vehicle1" name="vehicle1" onClick={(e) => checkvalidate(e)} />
                                                    <label for="vehicle1" style={{ marginLeft: "10px" }}> {id == "Delivery" ? "Pay at the time of Delivery" : "Pay at the time of Pickup"}</label>
                                                </div> */}

                                        {/* <!-- <div class="payattime">
                    <input type="checkbox">
                    <label>Pay at the time of Delivery</label>
                </div> --> */}






                                    </div>}

                                <div class="row">

                                    <div class="col">
                                        <button onClick={() => placeorder()} class="lst3btn" >Place Order </button>
                                    </div>


                                    <div class="col">
                                        <Link onClick={() => history(-1)}><button class="lst3btn" >Back To Cart </button></Link>
                                    </div>

                                    <div class="col">
                                        <Link onClick={() => window.location = "/"}><button class="lst3btn" >Continue Shopping </button></Link>
                                    </div>
                                </div>

                            </div>














                            <div class="col-lg-6">
                                {/* <div class="card" style={{ padding: "15px" }}>
                                    <img src={require('../../assets/Animation.gif')} alt="" />
                                    {/* <p style={{ marginTop: "12px" }}>
                                        "During this difficult time, our fulfilment & delivery staff are doing best to serve our customer.
                                        Would you like to reward our delivery staff who are working hard to deliver the goods during the pandemic
                                        and extreme circumstance? Add your Tips - It is optional."
                                    </p> */}








                                {/* </div> */}

                                {/* 
        {id == "Delivery" ?
        <>
        <div>
            <label class="mt10">
                Notes For Delivery:
            </label>
         
        </div>
        <div>
            <textarea placeholder="Give Any Delivery Instructions">
                
                
            </textarea>
        </div>
        </>:null} */}

                                {storeprice.coupenamt <= 0 ?
                                    <div class="card couponstart">
                                        <label class="marlef">
                                            Enter The Coupon Code
                                        </label>
                                        <div class="row" style={{ alignItems: "baseline" }}>


                                            <div class="col">



                                                <input
                                                    value={coupentext}
                                                    type="text"
                                                    class="form-control marlef marbotm grey-placeholder"
                                                    id="formGroupExampleInput"
                                                    placeholder="Code Number"
                                                    onChange={(e) => (setCoupentext(e.target.value), setcouponErr(""))}
                                                />


                                                {couponErr ?
                                                    <label style={{ color: "red" }} >{couponErr}</label> : null}

                                                {/* {coupenerr.checkcoupen ?
                                                    <label style={{ color: "red" }} >please enter coupon code</label> : null}
                                                {coupenerr.validcoupen ?
                                                    <label style={{ color: "red" }} >please enter valid coupon code</label> : null} */}
                                            </div>
                                            <div class="col">
                                                {totacal.coupenamt <= 0 ?
                                                    <button onClick={() => coupenvalidate()} class="btn coupbtnz marbotm">Apply</button> : null}

                                                {/* {price.coupenamt > 0 ?
                                                <button class="btn coupbtnz marbotm" style={{ marginLeft: "4px" }} onClick={() => {
                                                    setCoupentext('')
                                                    var data = { ...price }
                                                    if (deliverytype == "standard") {
                                                        data.grandtotal = Number(total) + Number(data.tax) + Number(data.tip) + Number(data.shipping.standard)
                                                    }
                                                    else {
                                                        data.grandtotal = Number(total) + Number(data.tax) + Number(data.tip) + Number(data.shipping.express)
                                                    }

                                                    data.coupenamt = 0
                                                    setPrice(data)

                                                }} > Clear</button> : null} */}


                                            </div>

                                        </div>


                                    </div>

                                    :
                                    <div class="card couponstart">
                                        <div class="row" style={{ alignItems: "baseline" }}>
                                            <div class="col">
                                                <label style={{ color: "green" }} >Coupon Applied Successfully</label>
                                            </div>
                                            <div class="col">
                                                {totacal.coupenamt > 0 ?
                                                    <button class="btn coupbtnz marbotm" style={{ marginLeft: "4px" }} onClick={() => CouponClear()}
                                                    >Clear</button>
                                                    : null}
                                            </div>
                                        </div>

                                    </div>
                                }



                                <div class="card couponstart">
                                    <label class="marlef">
                                        Notes
                                    </label>
                                    <div class="row" style={{ alignItems: "baseline" }}>


                                        <div class="col-9 contactus">


                                            <textarea value={notetext} type="text" id="formGroupExampleInput" onChange={(e) => setNotetext(e.target.value)}> </textarea>




                                        </div>


                                    </div>


                                </div>




                            </div>






                        </div>





                    </div>


                    {/* </div> */}




                </section>

                {/* 
<!-- Check Out Page Section Ends --> */}


            </div>













        </>
    );
}